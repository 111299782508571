/**
 * 預設關鍵字清單格式
 * id: 如果有客製化關鍵字，就紀錄 lineKeywords 的 id
 * storeId: 如果有客製化關鍵字，就紀錄 lineKeywords 的 storeId
 * originKeyword: 系統預設關鍵字清單
 * currentKeyword: 如果有客製化關鍵字，就紀錄 lineKeywords 的 currentKeyword
 * customSetting: 哪些關鍵字可以客製訊息
 * desciption: 關鍵字使用描述
 */
let defaultKeywords = [
  {
    id: null,
    storeId: null,
    originKeyword: "關鍵字清單",
    currentKeyword: null,
    customSetting: true,
    desciption: "可以在群組中得到所有關鍵字 ( 群組內皆可 )",
  },
  {
    id: null,
    storeId: null,
    originKeyword: "推播商品",
    currentKeyword: null,
    customSetting: true,
    desciption: "可以將賣場內待推播列表中的商品推播到群組上 ( 僅限賣家/小幫手 )",
  },
  {
    id: null,
    storeId: null,
    originKeyword: "個人菜單",
    currentKeyword: null,
    customSetting: true,
    desciption: '方便讓您賣場中的顧客快速前往查看"訂單"與"帳單" ( 群組內皆可 )',
  },
  {
    id: null,
    storeId: null,
    originKeyword: "商品許願",
    currentKeyword: null,
    customSetting: true,
    desciption: "可以讓您的顧客前往許願頁面進行許願 ( 群組內皆可 )",
  },
  {
    id: null,
    storeId: null,
    originKeyword: "抽獎",
    currentKeyword: null,
    customSetting: true,
    desciption: "顯示抽獎清單 ( 僅限賣家/小幫手 )",
  },
  {
    id: null,
    storeId: null,
    originKeyword: "開始直播",
    currentKeyword: null,
    customSetting: false,
    desciption: "Line 群組開始直播 ( 僅限賣家/小幫手 )",
  },
  {
    id: null,
    storeId: null,
    originKeyword: "目前商品",
    currentKeyword: null,
    customSetting: false,
    desciption: "推送直播列表中的第一順位商品 ( 僅限賣家/小幫手 )",
  },
  {
    id: null,
    storeId: null,
    originKeyword: "下一個商品",
    currentKeyword: null,
    customSetting: false,
    desciption: "推送直播列表中的第二順位商品 ( 僅限賣家/小幫手 )",
  },
]

/**
 * 取得預設關鍵字清單 (含已設定的客製關鍵字)
 * @param {*} defaultKeywords service 內設定好的預設關鍵字清單格式
 * @param {*} alreadySetKeywords api 取回的該賣場已設定的關鍵字清單
 */
function setDefaultKeywords(defaultKeywords, alreadySetKeywords) {
  // 初始化
  lineKeywordsService.keywords = [];
  lineKeywordsService.existKeywords = new Map();

  // 紀錄資料庫已設定的關鍵字清單
  let alreadySetKeywordsMap = new Map();
  alreadySetKeywords.forEach(keyword => {
    const originKeyword = keyword.originKeyword.trim();
    if (!alreadySetKeywordsMap.has(originKeyword)) {
      alreadySetKeywordsMap.set(originKeyword, keyword);
    }
  });

  // 將資料庫已設定的關鍵字清單與預設關鍵字清單合併整理
  defaultKeywords.forEach(defaultKeyword => {
    if (alreadySetKeywordsMap.has(defaultKeyword.originKeyword)) {
      const alreadySetKeywords = alreadySetKeywordsMap.get(defaultKeyword.originKeyword);
      defaultKeyword.id = alreadySetKeywords.id;
      defaultKeyword.storeId = alreadySetKeywords.storeId;
      defaultKeyword.currentKeyword = alreadySetKeywords.currentKeyword;
    } else {
      defaultKeyword.id = null;
      defaultKeyword.storeId = null;
      defaultKeyword.currentKeyword = null;
    }
  })

  // 紀錄整理好的資訊
  lineKeywordsService.existKeywords = alreadySetKeywordsMap;
  lineKeywordsService.keywords = defaultKeywords;
}

let lineKeywordsService = {
  keywords: [], // 整理過的關鍵字清單
  existKeywords: new Map(), // 已存在資料庫內的自定義關鍵字清單
  setDefaultKeywords, // 取得預設關鍵字清單 (含已設定的客製關鍵字)
}

export { lineKeywordsService, defaultKeywords }