// 引入 cookie 設定
import { setCookie, getCookie, delCookie } from '../../global/cookie'

class LineKeywordsApi {
  /**
   * 取得自訂關鍵字列表
   * @param {*} storeId 賣場 ID
   * @returns promise
   */
  getLineKeywords(storeId) {
    const api = `${process.env.VUE_APP_API}/lineKeywords/getLineKeywords?storeId=${storeId}`
    const header = {
      authorization: getCookie("serverToken")
    }
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: 'GET',
        async: true,
        url: api,
        headers: header,
        processData: false,
        contentType: false,
        success: resolve,
        error: resject,
      });
    })
    return result
  }

  /**
   * 建立自訂 Line 關鍵字
   * @param {*} data json 格式 
   * @returns promise
   * 
   * data = [
   *  {
   *    storeId: 賣場 ID,
   *    originKeyword: 原始關鍵字名稱
   *    currentKeyword: 要自定義的關鍵字名稱
   *  }...
   * ]
   */
  createLineKeywords(data) {
    const api = `${process.env.VUE_APP_API}/lineKeywords/createLineKeywords`
    const header = {
      authorization: getCookie("serverToken")
    }
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: 'POST',
        async: true,
        url: api,
        headers: header,
        data: JSON.stringify(data),
        contentType: "application/json",
        success: resolve,
        error: resject,
      });
    })
    return result
  }

  /**
   * 更新 / 刪除自訂 Line 關鍵字
   * @param {*} json 格式 
   * @returns promise
   * 
   * data = [
   *  {
   *    id: lineKeywords id,
   *    storeId: 賣場 ID,
   *    originKeyword: 原始關鍵字名稱
   *    currentKeyword: 要自定義的關鍵字名稱 (若沒有 currentKeyword 則做刪除處理)
   *  }
   * ]
   */
  updateLineKeywords(data) {
    const api = `${process.env.VUE_APP_API}/lineKeywords/updateLineKeywords`
    const header = {
      authorization: getCookie("serverToken")
    }
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: 'PUT',
        async: true,
        url: api,
        headers: header,
        data: JSON.stringify(data),
        contentType: "application/json",
        success: resolve,
        error: resject,
      });
    })
    return result
  }
}

const lineKeywordsApi = new LineKeywordsApi();

export { lineKeywordsApi };