<template>
  <div>
    <!-- Line 群組歡迎 -->
    <div class="border p-3 rounded rounded-5 mb-3">
      <p class="fw-bolder mb-3">Line 群組歡迎設定</p>
      <ul v-if="!storeLineGroupLinks.loading">
        <template v-if="storeLineGroupLinks.data.length > 0">
          <li v-for="data in storeLineGroupLinks.data" :key="data.id">
            <div class="form-check form-switch p-0">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                :id="`LineGroupWelcomeSwitch${data.id}`"
                v-model="data.greet"
                @change="setLineAttributes('setGreet', data)"
              />
              <label
                class="form-check-label"
                :for="`LineGroupWelcomeSwitch${data.id}`"
                >開啟
                <span class="text-primary fw-bolder">{{ data.groupName }}</span>
                歡迎功能</label
              >
            </div>
            <div class="form-floating mb-3" v-if="data.greet">
              <textarea
                class="form-control"
                placeholder="Leave a comment here"
                :id="`LineGroupWelcomeText${data.id}`"
                style="height: 200px"
                maxlength="4000"
                v-model="data.greetingMessage"
              ></textarea>
              <label :for="`LineGroupWelcomeText${data.id}`"
                >歡迎字樣(4000字)</label
              >
            </div>
            <div class="text-end" v-if="data.greet">
              <button
                class="tw-btn tw-btn-success"
                @click="setLineAttributes('setGreetingMessage', data)"
              >
                儲存歡迎字樣
              </button>
            </div>
          </li>
        </template>
        <p class="alert alert-danger fw-bolder p-2" v-else>
          您尚未在此賣場綁定任何 Line Bot 群組 !!
          <router-link :to="`/seller/store/${storeId}/social`"
            >點我前往綁定</router-link
          >
        </p>
      </ul>
      <AreaLoading v-else></AreaLoading>
    </div>
    <!-- line 推播列表文字 -->
    <div class="border p-3 rounded rounded-5 mb-3">
      <p class="fw-bolder mb-3">Line 推播列表文字</p>
      <div class="form-floating mb-3">
        <input
          type="text"
          class="form-control"
          id="LineD4Text"
          placeholder="預設文字10字"
          maxlength="10"
          v-model="linePushD4Text.value"
        />
        <label for="LineD4Text">預設文字(10字)</label>
      </div>
      <div class="text-end">
        <button
          class="tw-btn tw-btn-success"
          @click="
            updateConfiguration(
              'p_merchandise',
              'linePushD4Text',
              linePushD4Text
            )
          "
        >
          儲存
        </button>
      </div>
    </div>
    <!-- Line 推播樣式 -->
    <div class="border p-3 rounded rounded-5 mb-3">
      <p class="fw-bolder mb-3">Line 推播樣式</p>
      <ul v-if="!storeLineGroupLinks.loading">
        <template v-if="storeLineGroupLinks.data.length > 0">
          <li v-for="data in storeLineGroupLinks.data" :key="data.id">
            <p class="text-primary fw-bolder mb-2">{{ data.groupName }}</p>
            <div class="form-check form-switch p-0">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                :id="`PushCarousel${data.id}`"
                v-model="data.pushCarousel"
                @change="setLineAttributes('setPushCarousel', data)"
              />
              <label class="form-check-label" :for="`PushCarousel${data.id}`">
                橫幅顯示
              </label>
            </div>
            <div class="form-check form-switch p-0">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                :id="`PushQueueInfo${data.id}`"
                v-model="data.pushQueueInfo"
                @change="setLineAttributes('setPushQueueInfo', data)"
              />
              <label class="form-check-label" :for="`PushQueueInfo${data.id}`">
                包含記事本格式
              </label>
            </div>
          </li>
        </template>
        <p class="alert alert-danger fw-bolder p-2" v-else>
          您尚未在此賣場綁定任何 Line Bot 群組 !!
          <router-link :to="`/seller/store/${storeId}/social`"
            >點我前往綁定</router-link
          >
        </p>
      </ul>
      <AreaLoading v-else></AreaLoading>
    </div>
    <!-- 發布到 line notify 下單連結位置 -->
    <div class="border p-3 rounded rounded-5 mb-3">
      <p class="fw-bolder mb-3">發布到 line notify 下單連結位置</p>
      <div class="select-radio">
        <label v-for="data in placeOnOrderUrlPosition" :key="data.id">
          <input
            type="radio"
            name="rows"
            :checked="data.selected"
            @click="
              updateConfiguration('p_line', 'placeOnOrderUrlPosition', data)
            "
          />
          <span class="radio-style">{{
            data.value === "d4" ? "預設" : "最下面"
          }}</span>
        </label>
      </div>
    </div>
    <!-- Line 關鍵字 -->
    <div class="border p-3 rounded rounded-5 mb-3">
      <p class="fw-bolder mb-3">Line 關鍵字</p>
      <template v-if="lineKeywords">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>關鍵字</th>
              <th>描述</th>
              <th v-if="settingForLinekeywords">
                <p>客製化關鍵字(10個字)</p>
                <p class="text-danger">請勿使用表情符號</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(lineKeyword, index) in lineKeywords" :key="index">
              <td>{{ lineKeyword.originKeyword }}</td>
              <td>{{ lineKeyword.desciption }}</td>
              <td v-if="settingForLinekeywords">
                <input type="text" maxlength="10" v-if="lineKeyword.customSetting" v-model="lineKeyword.currentKeyword">
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-end" v-if="settingForLinekeywords">
          <button class="tw-btn tw-btn-success" @click="saveLineKeywords">儲存</button>
        </div>
      </template>
      <p class="text-danger fw-bolder" v-else>請稍後~</p>
    </div>
  </div>
</template>

<script>
// vuex
import { mapState } from "vuex";
// service
import { lineKeywordsService, defaultKeywords } from '../../../../methods/service/lineKeywordsService'
// api
import { lineKeywordsApi } from '../../../../methods/API/normal/lineKeywordsApi'

export default {
  data() {
    return {
      storeId: 0,
      serverToken: '',
      storeLineGroupLinks: {
        loading: false,
        data: [],
      },
      lineKeywords: null,
    }
  },
  created() {
    this.initialization()
    this.getPrefer()
    this.getStoreInfo()
    this.getLineKeywords()
  },
  computed: {
    linePushD4Text() {
      return this.$store.state.Prefer.p_merchandise.linePushD4Text[0]
    },
    placeOnOrderUrlPosition() {
      return this.$store.state.Prefer.p_line.placeOnOrderUrlPosition
    },
    ...mapState("SystemSetting", {
      settingForLinekeywords: state => state.settingForLinekeywords,
    }),
  },
  methods: {
    initialization() {
      this.storeId = this.$route.params.storeId
      this.serverToken = this.$methods.getCookie('serverToken')
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 取得 line 資訊
    getStoreInfo() {
      this.$methods.switchLoading('show')
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getStoreLineGroupLinks{getSummary}}'
        }
      ]
      this.storeLineGroupLinks = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            if (storeInfo.storeLineGroupLinks.length === 0) vm.storeLineGroupLinks.loading = false
            else {
              storeInfo.storeLineGroupLinks.forEach(item => {
                if (!item.deleted) {
                  vm.storeLineGroupLinks.data.push({
                    deleted: item.deleted,
                    id: item.id,
                    greet: item.greet,
                    greetingMessage: item.greetingMessage,
                    groupId: item.groupId,
                    storeId: item.storeId,
                    groupName: item.summary.groupName,
                    pushCarousel: item.pushCarousel,
                    pushQueueInfo: item.pushQueueInfo,
                  })
                }
              })
              vm.storeLineGroupLinks.loading = false
            }
        }
          setTimeout(() => {
            vm.$methods.switchLoading('hide')
          }, 500)
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 更新偏好
    updateConfiguration(category, item, data) {
      if (item === 'linePushD4Text' && !data.value) return this.SweetAlert('other', '預設文字不能為空')

      this.$store.dispatch('Prefer/updateConfiguration', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken'),
        category: category,
        item: item,
        data: data
      })
    },
    // 更新 Line 偏好
    setLineAttributes(updateItem, item) {
      const vm = this
      const setGroupAttributeApi = `${process.env.VUE_APP_API}/apps/line/setGroupAttribute`
      const header = {
        authorization: this.serverToken
      }
      const data = []
      switch (updateItem) {
        case 'setGreet':
          data.push({
            id: item.id,
            attributes: {
              setGreet: item.greet
            },
          })
          break;
        case 'setGreetingMessage':
          data.push({
            id: item.id,
            attributes: {
              setGreetingMessage: item.greetingMessage
            },
          })
          break;
        case 'setPushCarousel':
          data.push({
            id: item.id,
            attributes: {
              setPushCarousel: item.pushCarousel
            },
          })
          break;
        case 'setPushQueueInfo':
          data.push({
            id: item.id,
            attributes: {
              setPushQueueInfo: item.pushQueueInfo
            },
          })
          break;
        default:
          throw new Error('PS_Line.vue > setLineAttributes > updateItem 未處理')
      }
      this.$methods.switchLoading('show')
      $.ajax({
        type: 'PUT',
        async: true,
        url: setGroupAttributeApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.getStoreInfo()
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 取得 line 自訂關鍵字清單
    getLineKeywords() {
      this.lineKeywords = null;
      lineKeywordsApi.getLineKeywords(this.storeId).then(res => {
        if (res.code === "200") {
          const dbKeywords = res.data;
          lineKeywordsService.setDefaultKeywords(defaultKeywords, dbKeywords);
          this.lineKeywords = lineKeywordsService.keywords;
          this.$methods.switchLoading('hide')
        }
      }).catch(err => {
        console.log(err.responseJSON)
        if (err.responseJSON) this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      });
    },
    // 儲存 line 自訂關鍵字
    async saveLineKeywords() {
      this.$methods.switchLoading('show')
      // 1. 整理 "建立" 和 "更新 / 刪除" 的資料
      let createData = [];
      let updateData = [];
      this.lineKeywords.forEach(lineKeyword => {
        const currentKeyword = lineKeyword.currentKeyword ? lineKeyword.currentKeyword.trim() : null;
        // 建立
        if (!lineKeyword.id && currentKeyword) {
          createData.push({
            storeId: this.storeId,
            originKeyword: lineKeyword.originKeyword,
            currentKeyword: currentKeyword
          });
        }

        // 更新 / 刪除
        if ((lineKeyword.id && currentKeyword) || (lineKeyword.id && !currentKeyword)) {
          updateData.push({
            id: lineKeyword.id,
            storeId: this.storeId,
            originKeyword: lineKeyword.originKeyword,
            currentKeyword: currentKeyword
          });
        }
      })

      let success = true;
      // 2. 呼叫建立 API
      if (createData.length > 0) {
        await lineKeywordsApi.createLineKeywords(createData).then(res => {
          console.log(res)
        }).catch(err => {
          console.log(err.responseJSON)
          if (err.responseJSON) this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          this.$methods.switchLoading('hide')
          success = false
        });
      }

      // 3. 呼叫更新 / 刪除 API
      if (success && updateData.length > 0) {
        await lineKeywordsApi.updateLineKeywords(updateData).then(res => {
          console.log(res)
        }).catch(err => {
          console.log(err)
          if (err.responseJSON) this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          this.$methods.switchLoading('hide')
          success = false
        });
      }

      if (success) {
        this.SweetAlert("200");
        this.getLineKeywords();
      }
    },
  },
}
</script>